@font-face {
    font-family: 'Cinzel-Bold';
    src: url('fonts/Cinzel-Bold.ttf');
    src: url('fonts/Cinzel-Bold.ttf')  format('truetype');
}

@font-face {
    font-family: 'Cinzel-Regular';
    src: url('fonts/Cinzel-Regular.ttf');
    src: url('fonts/Cinzel-Regular.ttf')  format('truetype');
}

@font-face {
    font-family: 'Alegreya-Regular';
    src: url('fonts/Alegreya-Regular.ttf');
    src: url('fonts/Alegreya-Regular.ttf')  format('truetype');
}

@font-face {
    font-family: 'Alegreya-Italic';
    src: url('fonts/Alegreya-Italic.ttf');
    src: url('fonts/Alegreya-Italic.ttf')  format('truetype');
}

@font-face {
    font-family: 'Maven_Pro';
    src: url('fonts/MavenPro-Regular.eot');
    src: url('fonts/MavenPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/MavenPro-Regular.woff2') format('woff2'),
    url('fonts/MavenPro-Regular.woff') format('woff'),
    url('fonts/MavenPro-Regular.ttf') format('truetype'),
    url('fonts/MavenPro-Regular.svg#MavenPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
